<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>生产单</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>
    </TopMenu>

    <div class="content bg-25 p-2 flex-1">
      <div class="flex-list mb-2">
        <div style="width:50%;">
          <label class="font-12">工人名称</label>
          <inputSelect v-model="production.user_id" label="nickname" :options="user" placeholder="请选择供工人名称" />
        </div>
        
        <div style="width:25%;">
            <DatePicker
            v-model="production.date_at"
            :model-config="modelConfig"
            is-dark
            :attributes="attrs"
            :popover="{ visibility: 'click' }"
            @dayclick='dayClicked'
            >
              <template #default="{ inputValue, inputEvents }">
                <label class="font-12">选择日期</label>
                <input type="text" class="form-control" placeholder="编制日期" :value="inputValue" v-on="inputEvents" >
              </template>
            </DatePicker>
        </div>
        <div style="width:25%;">
          <label class="font-12">单号</label>
          <input type="text" @change="handleNumber" class="form-control" placeholder="订单号" v-model="production.number">
        </div>
      </div>

      <div class="d-flex">
        <div class="flex-list-header"></div>
        <div class="flex-list hr w-100">
          <div style="width:40%;padding-left:24px;">产品</div>
          <div style="width:20%;">规格</div>
          <div style="width:20%;">件数</div>
          <div style="width:20%;">数量</div>
        </div>
      </div>

      <div class="d-flex" v-for="(item, index) in production.list" :key="index">
          <div class="flex-list-header">
            <div class="bg-38">{{ index + 1 }}</div>
            <div class="delete bg-red" @click="deleteRow(index)">-</div>
          </div>
          <div class="flex-list hr w-100">
            <div style="width:40%;padding-left:24px;">
                <inputSelect v-model="production.list[index].product_id" label="name" :options="products" placeholder="请选择产品" inputClass="form-control p-0 bg-none" />
            </div>
            <div style="width:20%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="production.list[index].specification">
            </div>
            <div style="width:20%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="production.list[index].number" @input="onNumberInput(index)">
            </div>
            <div style="width:20%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="production.list[index].quantity" @input="onQuantityInput(index)">
            </div>
          </div>
      </div>
      <button class="btn btn-create p-0" @click="create()">+</button>

      <div class="mb-2 mt-1">
        <label class="font-12">备注</label>
        <textarea style="resize: none;" class="form-control" placeholder="备注" v-model="production.remarks"></textarea>
      </div>
      <div class="flex-list">
        <div style="width:50%">
          <label class="font-12">制单人</label>
          <input type="text" class="form-control" placeholder="制单人" v-model="production.prepared_by">
        </div>
      </div>

    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
import inputSelect from '@/components/inputSelect.vue'
import { DatePicker } from 'v-calendar';
import formatDate from '@/utils/formatDate.js';

export default {
  name: 'ProductionEdit',

  data() {
    return {
      loading: false,
      production: {
        id: null,
        number: null,
        user_id: null,
        remarks: null,
        date_at: formatDate.getNowFormatDay(),
        prepared_by: JSON.parse(sessionStorage.Authorization).nickname,
        list:[
          {
            id: null,
            product_id: null,
            name: null,
            specification: null,
            number: null,
            quantity: null,
          }
        ],
      },
      products: [],
      user: [],
      number: null, //修改时保存初始单号
      userSelect: {
        show: false,
        top: 0,
        left: 0
      },
      allowAttack: false,
      attrs: [
        {
          key: 'today',
          highlight: 'gray',
          dates: new Date(),
        },
      ],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      }
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    this.getProductList()
    this.getUserList()
    this.getLastNumber(new Date())

    if (this.$route.params.id) {
      this.updateProduction()
      console.log('修改')
    }
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    inputSelect,
    DatePicker,
  },

  methods: {
    onNumberInput(index) {
      this.production.list[index].quantity = this.$math.Mul(this.production.list[index].number, this.production.list[index].specification)
    },
    onQuantityInput(index) {
      this.production.list[index].number = this.$math.Div(this.production.list[index].quantity, this.production.list[index].specification)
    },


    //获取产品列表
    getProductList(customer) {
      this.$axios.get('/api/product', {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
        params: {
          customer: customer
        }
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.products = res.data.product
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    //获取客户列表
    getUserList() {
      this.$axios.get('/api/user', {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.user = res.data.user
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    //获取订单单号
    getLastNumber(date_at) {
      let _this = this
      return new Promise((resolve, reject) => {
        _this.$axios.post('/api/production/number',
          {
            date_at: date_at
          },
          {
            headers: {
              token: JSON.parse(sessionStorage.Authorization).token
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            resolve(res);
            if (res.data) {
              console.log(_this.production.number)
              if (_this.production.number == null) {
                _this.production.number = res.data
                _this.number = res.data
              }
            }
          }
        })
        .catch(error => {
          reject(error)
          console.log('error:', error)
        })
      })
    },

    //更新订单
    updateProduction() {
      this.$axios.get('/api/production/'+this.$route.params.id, {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.production = res.data
            this.number = res.data.number
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    // 订单号输入处理
    handleNumber(e) {
      //let _this = this

      if(e.target.value.length > 12){
        this.production.number = e.target.value.slice(0,12)
      } else if(e.target.value.length < 12) {
        this.production.number = this.number
      }

      //传送对比是否重复
      if (this.production.number != this.number) {
        this.$axios.post('/api/production/check-number',
          {
            number: this.production.number
          },
          {
            headers: {
              token: JSON.parse(sessionStorage.Authorization).token
            }
          }
        )
        .then(res => {
          if (res.status == 200) {
            if (res.data.success) {
              this.$toast.success(res.data.message)
            } else {
              this.$toast.error(res.data.message)
              this.production.number = this.number
            }
          }
        })
        .catch(error => {
        console.log('error:', error)
        })
      }

    },

    dayClicked() {
      console.log(this.production.date_at)

      // 对比月份是否有改变
      let date_at = new Date(this.production.date_at);
      let nowMonth = new Date();
      if (date_at.getMonth() == nowMonth.getMonth()) {
        // 如果是修改订单
        if (this.number) {
          this.production.number = this.number
        }
      } else {
        let _this = this
        this.getLastNumber(this.production.date_at).then(res => {
          _this.production.number = res.data
        })
      }
    },

    create() {
      this.production.list.push({
        id: null,
        name: null,
        specification: null,
        number: null,
        quantity: null,
        price: null,
        total: 0,
      })
    },

    deleteRow(index) {
      if (this.production.list.length > 1) {
        this.production.list.splice(index, 1)
      }
    },

    destroy() {
      this.$router.push({name: 'productionList'})
    },

    save() {
      let _this = this
      this.allowAttack = true
      return new Promise((resolve, reject) => {
        try {
          if (this.production.number==null) throw '请填写单号'
          if (this.production.user_id==null) throw '请选择生产工人'
          if (this.production.date_at==null) throw '请选择发货日期'

          // 正则验证是否填写数字
          let reg = /^[0-9]+.?[0-9]*/;
          this.production.list.forEach(function(item) {
            if (!reg.test(item.product_id) || !reg.test(item.specification) || !reg.test(item.number) || !reg.test(item.quantity)) throw '请填写必要的信息'
          })

          
          _this.$axios.post('/api/production/save',
            {
              production: _this.production
            },
            {
              headers: {
                token: JSON.parse(sessionStorage.Authorization).token
              }
            }
          )
          .then(res => {
            _this.allowAttack = false

            if (res.status == 200) {
              resolve(res);
              console.log(res.data)
              if (res.data.success) {
                this.production.id = res.data.data.id
                this.$toast.success(res.data.message)
              } else {
                this.$toast.error(res.data.message)
              }
              
            }
          })
          .catch(error => {
            reject(error)
            _this.allowAttack = false
            console.error('error:', error)
          })
         
        } catch(error) {
          console.error(error)
          _this.allowAttack = false
          this.$toast.error(error)
        }
      })
    },

    saveAndPrint() {
      this.save().then(res => {
        if (res.data.success) {
          this.$router.push({name: 'productionPrint', params: {id: res.data.data.id}})
        }
      })
    },
  },

  watch: {
    'production.user_id': function() {
      this.getProductList(this.production.user_id)
    },

    ProductionDatas: {
      handler: function(val, oldVal) {
        let _this = this
        let value = JSON.parse(val)
        let oldValue = JSON.parse(oldVal)
        let rowPrice = []
        let totalPrice = 0

        for (let i = 0; i < value.length; i++) {
          if (value[i] != oldValue[i]) {
              //更新产品规格
              if (oldValue[i]) {
                if (value[i].product_id != oldValue[i].product_id) {
                  for (let t = 0; t < this.products.length; t++) {
                    if (this.products[t].id == this.production.list[i].product_id) {
                      this.production.list[i].specification = this.products[t].specification
                      // 自动填充默认单价
                      if (!this.production.list[i].price) {
                        this.production.list[i].price = this.products[t].price
                      }
                      
                    }
                  }
                }

                if (value[i].specification != oldValue[i].specification) {
                  this.production.list[i].number = this.$math.Div(this.production.list[i].quantity, this.production.list[i].specification)
                }
                
              } else {
                for (let t = 0; t < this.products.length; t++) {
                  if (this.products[t].id == this.production.list[i].product_id) {
                    this.production.list[i].specification = this.products[t].specification
                  }
                }

                if (value[i].specification) {
                  this.production.list[i].number = this.$math.Div(this.production.list[i].quantity, this.production.list[i].specification)
                }
              }

              // 计算单行总价
              this.production.list[i].total = this.$math.Mul(this.production.list[i].quantity, this.production.list[i].price)

              if (!isNaN(this.production.list[i].total)) {
                rowPrice[i] = this.production.list[i].total
              }
          }
        }

        // 计算所有总价
        rowPrice.forEach(function(item) {
          totalPrice = _this.$math.Add(totalPrice, item)
        })
        this.totalPrice = totalPrice
        
      },
      deep: true
    }
  },

  computed: {
    formatMoney() {
      /**
       * @description 格式化金额
       * @param number：要格式化的数字
       * @param decimals：保留几位小数 默认0位
       * @param decPoint：小数点符号 默认.
       * @param thousandsSep：千分位符号 默认为,
       */
      return (number, decimals = 0, decPoint = '.', thousandsSep = ',') => {
        return this.$math.formatMoney(number, decimals, decPoint, thousandsSep)
      }
    },

    ProductionDatas() {
      return JSON.stringify(this.production.list)
    }

  }
}

</script>

<style lang="scss" scoped>

.flex-list-header .delete {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flex-list-header:hover .delete {
  display: block;
}
.btn-create {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding: 0;
  flex-shrink: 0;
  background-color: #004bff;
}

::v-deep .vc-container.vc-is-dark {
  color: #fff;
  background-color: #383838;
  border: none;
}
 
</style>
